import { render, staticRenderFns } from "./privateFile.vue?vue&type=template&id=9156ff52&scoped=true"
import script from "./privateFile.vue?vue&type=script&lang=js"
export * from "./privateFile.vue?vue&type=script&lang=js"
import style0 from "./privateFile.vue?vue&type=style&index=0&id=9156ff52&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9156ff52",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/rennannan/Desktop/hhsoft项目/Vue/(H5)博士攻略-企业客户端/youzan-h5/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9156ff52')) {
      api.createRecord('9156ff52', component.options)
    } else {
      api.reload('9156ff52', component.options)
    }
    module.hot.accept("./privateFile.vue?vue&type=template&id=9156ff52&scoped=true", function () {
      api.rerender('9156ff52', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/page/homePage/components/privateFile.vue"
export default component.exports