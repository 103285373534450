var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "fr-b-c header" },
      [
        _c("span", [
          _vm._v(
            "面诊档案 " +
              _vm._s(_vm.skintest ? `(${_vm.skintest.interviewTime})` : "")
          ),
        ]),
        _c(
          "el-button",
          {
            staticClass: "btn",
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.chiefGo },
          },
          [_vm._v("查看历史档案")]
        ),
      ],
      1
    ),
    _vm.tableData.length && _vm.activeNames
      ? _c(
          "div",
          { staticClass: "interview-file-body-box" },
          [
            _c(
              "el-table",
              {
                staticClass: "tables",
                staticStyle: { width: "100%" },
                attrs: { size: "mini", data: _vm.tableInfo },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "degree",
                    label: "",
                    width: "50",
                    align: "center",
                    fixed: "",
                  },
                }),
                _vm._l(_vm.tableData, function (item, index) {
                  return _c(
                    "el-table-column",
                    {
                      key: index,
                      attrs: {
                        prop: "date",
                        label: item.classifyName,
                        "min-width": "80",
                        align: "center",
                      },
                    },
                    [
                      item.leafClassifyList.length > 0
                        ? _c(
                            "div",
                            _vm._l(
                              item.leafClassifyList,
                              function (items, indexs) {
                                return _c("el-table-column", {
                                  key: indexs,
                                  attrs: {
                                    prop: "province",
                                    label: items.classifyName,
                                    "min-width": "80",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.$index == 0
                                              ? _c("div", [
                                                  items.level
                                                    ? _c(
                                                        "div",
                                                        {
                                                          class: [
                                                            "close",
                                                            items.level.color
                                                              ? "on"
                                                              : "",
                                                          ],
                                                          style: {
                                                            background: items
                                                              .level.color
                                                              ? items.level
                                                                  .color
                                                              : "#f7f7f7",
                                                          },
                                                        },
                                                        [
                                                          items.classifyCode ==
                                                          "skinColor"
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "getStateName"
                                                                    )(
                                                                      items
                                                                        .level
                                                                        .value,
                                                                      _vm.colorType
                                                                    )
                                                                  )
                                                                ),
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "getStateName"
                                                                    )(
                                                                      items
                                                                        .level
                                                                        .value,
                                                                      _vm.degreeType
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                            scope.$index == 1
                                              ? _c("div", [
                                                  items.area
                                                    ? _c(
                                                        "div",
                                                        {
                                                          class: [
                                                            "close",
                                                            items.area.color
                                                              ? "on"
                                                              : "",
                                                          ],
                                                          style: {
                                                            background: items
                                                              .area.color
                                                              ? items.area.color
                                                              : "#f7f7f7",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "getStateName"
                                                                )(
                                                                  items.area
                                                                    .value,
                                                                  _vm.AreaType
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }
                            ),
                            1
                          )
                        : _c("el-table-column", {
                            key: index,
                            attrs: {
                              prop: "date",
                              label: "",
                              "min-width": "80",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.$index == 0
                                        ? _c("div", [
                                            item.level
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "close",
                                                      item.level.color
                                                        ? "on"
                                                        : "",
                                                    ],
                                                    style: {
                                                      background: item.level
                                                        .color
                                                        ? item.level.color
                                                        : "#f7f7f7",
                                                    },
                                                  },
                                                  [
                                                    item.classifyCode ==
                                                    "skinColor"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "getStateName"
                                                              )(
                                                                item.level
                                                                  .value,
                                                                _vm.colorType
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : item.classifyCode ==
                                                        "skinType"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "getStateName"
                                                              )(
                                                                item.level
                                                                  .value,
                                                                _vm.skinType
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : item.classifyCode ==
                                                          "roughSkin" ||
                                                        item.classifyCode ==
                                                          "roughPore"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "getStateName"
                                                              )(
                                                                item.level
                                                                  .value,
                                                                _vm.enumType
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : item.classifyCode ==
                                                        "sensitiveType"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "getStateName"
                                                              )(
                                                                item.level
                                                                  .value,
                                                                _vm.sensitiveType
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "getStateName"
                                                              )(
                                                                item.level
                                                                  .value,
                                                                _vm.degreeType
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      scope.$index == 1
                                        ? _c("div", [
                                            item.area
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "close",
                                                      item.area.color
                                                        ? "on"
                                                        : "",
                                                    ],
                                                    style: {
                                                      background: item.area
                                                        .color
                                                        ? item.area.color
                                                        : "#f7f7f7",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "getStateName"
                                                          )(
                                                            item.area.value,
                                                            _vm.AreaType
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
            _c(
              "el-collapse",
              {
                model: {
                  value: _vm.activeNames,
                  callback: function ($$v) {
                    _vm.activeNames = $$v
                  },
                  expression: "activeNames",
                },
              },
              [
                _c(
                  "el-collapse-item",
                  { attrs: { title: "数据解读", name: "1" } },
                  [
                    _c(
                      "el-table",
                      {
                        staticClass: "tables",
                        staticStyle: { width: "100%" },
                        attrs: {
                          size: "mini",
                          border: "",
                          data: _vm.skintest.explainList,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "指标名",
                            prop: "fileNameDesc",
                            "min-width": "60",
                            fixed: "",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "值/范围",
                            prop: "configValue",
                            "min-width": "80",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.configValue ==
                                          "大于：0, 小于：1000"
                                          ? "0-100"
                                          : scope.row.configValue
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1674477569
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "现值",
                            prop: "matchValue",
                            "min-width": "80",
                            align: "center",
                          },
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "异常",
                              prop: "color",
                              "min-width": "80",
                              align: "center",
                            },
                          },
                          [[_vm._v("是")]],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: "解读内容",
                            prop: "explainMsg",
                            "min-width": "80",
                            align: "center",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }