var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm.dataInfo
      ? _c(
          "div",
          { staticClass: "fr-b-c header" },
          [
            _c("span", [
              _vm._v("检测档案（" + _vm._s(_vm.dataInfo.recordTime) + "）"),
            ]),
            _c(
              "el-button",
              {
                staticClass: "btn",
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.chiefGo },
              },
              [_vm._v("查看历史档案")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "detection-body-box" },
      [
        _vm.dataInfo
          ? _c(
              "el-descriptions",
              { attrs: { title: "", column: 2, border: "" } },
              [
                _c("el-descriptions-item", { attrs: { label: "肤色:" } }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("getStateName")(
                        _vm.dataInfo.skinColor[0].value,
                        _vm.colorType
                      )
                    )
                  ),
                ]),
                _c("el-descriptions-item", { attrs: { label: "肤龄:" } }, [
                  _vm._v(_vm._s(Number(_vm.dataInfo.skinAge[0].value))),
                ]),
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-table",
          {
            staticClass: "tables",
            staticStyle: { width: "100%" },
            attrs: { size: "mini", data: _vm.tableInfo },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "degree",
                label: "",
                width: "50",
                align: "center",
                fixed: "",
              },
            }),
            _vm._l(_vm.tableData, function (item, index) {
              return _c(
                "el-table-column",
                {
                  key: index,
                  attrs: {
                    prop: "date",
                    label: item.classifyName,
                    "min-width": "70",
                    align: "center",
                  },
                },
                _vm._l(item.leafClassifyList, function (items, indexs) {
                  return _c("el-table-column", {
                    key: indexs,
                    attrs: {
                      prop: "province",
                      label: items.classifyName,
                      "min-width": "70",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.$index == 0
                                ? _c(
                                    "div",
                                    _vm._l(
                                      items.level,
                                      function (item2, index2) {
                                        return _c(
                                          "div",
                                          {
                                            key: index2,
                                            class: [
                                              "close",
                                              item2.color ? "on" : "",
                                            ],
                                            style: {
                                              background: item2.color
                                                ? item2.color
                                                : "#f7f7f7",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item2.direction == "right"
                                                    ? "右"
                                                    : item2.direction == "left"
                                                    ? "左"
                                                    : "正"
                                                ) +
                                                ":" +
                                                _vm._s(item2.value)
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              scope.$index == 1
                                ? _c(
                                    "div",
                                    _vm._l(
                                      items.score,
                                      function (item2, index2) {
                                        return _c(
                                          "div",
                                          {
                                            key: index2,
                                            class: [
                                              "close",
                                              item2.color ? "on" : "",
                                            ],
                                            style: {
                                              background: item2.color
                                                ? item2.color
                                                : "#f7f7f7",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item2.direction == "right"
                                                    ? "右"
                                                    : item2.direction == "left"
                                                    ? "左"
                                                    : "正"
                                                ) +
                                                ":" +
                                                _vm._s(
                                                  _vm.$util.fmtToFix(
                                                    item2.value,
                                                    1
                                                  )
                                                )
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              scope.$index == 2
                                ? _c(
                                    "div",
                                    _vm._l(
                                      items.ratio,
                                      function (item2, index2) {
                                        return _c(
                                          "div",
                                          {
                                            key: index2,
                                            class: [
                                              "close",
                                              item2.color ? "on" : "",
                                            ],
                                            style: {
                                              background: item2.color
                                                ? item2.color
                                                : "#f7f7f7",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item2.direction == "right"
                                                    ? "右"
                                                    : item2.direction == "left"
                                                    ? "左"
                                                    : "正"
                                                ) +
                                                ":" +
                                                _vm._s(
                                                  _vm.$util.fmtToFix(
                                                    item2.value,
                                                    1
                                                  )
                                                )
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              )
            }),
          ],
          2
        ),
        _c(
          "el-collapse",
          {
            model: {
              value: _vm.activeNames,
              callback: function ($$v) {
                _vm.activeNames = $$v
              },
              expression: "activeNames",
            },
          },
          [
            _c(
              "el-collapse-item",
              { attrs: { title: "数据解读", name: "1" } },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "tables",
                    staticStyle: { width: "100%" },
                    attrs: { size: "mini", border: "", data: _vm.explainList },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "指标名",
                        prop: "fileNameDesc",
                        "min-width": "85",
                        fixed: "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "脸向",
                        prop: "direction",
                        "min-width": "80",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "值/范围",
                        prop: "configValue",
                        "min-width": "80",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  scope.row.configValue == "大于：0,小于：1000"
                                    ? "0-100"
                                    : scope.row.configValue
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "现值",
                        prop: "matchValue",
                        "min-width": "80",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.fmtToFix(scope.row.matchValue, 1)
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "异常",
                          prop: "color",
                          "min-width": "80",
                        },
                      },
                      [[_vm._v("是")]],
                      2
                    ),
                    _c("el-table-column", {
                      attrs: {
                        label: "解读内容",
                        prop: "explainMsg",
                        "min-width": "80",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }