var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-home-box" }, [
    _vm.dataInfo
      ? _c("div", { staticClass: "w-1" }, [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("div", { staticClass: "h-1" }, [
                _c("img", {
                  staticClass: "headerImg",
                  attrs: { src: _vm.dataInfo.avatarUrl, alt: "" },
                }),
              ]),
              _c(
                "el-descriptions",
                {
                  staticStyle: { flex: "1" },
                  attrs: {
                    size: "mini",
                    direction: "vertical",
                    title: "",
                    column: 3,
                    border: "",
                  },
                },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: "昵称",
                        "label-class-name": "my-label",
                        "content-class-name": "my-content",
                      },
                    },
                    [_vm._v(_vm._s(_vm.dataInfo.custName))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "用户类型" } }, [
                    _vm._v(_vm._s(_vm.dataInfo.vipLevel)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
                    _vm._v(_vm._s(_vm.dataInfo.custPhone)),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "性别" } },
                    [
                      _vm.dataInfo.gender
                        ? _c("el-tag", { attrs: { size: "small" } }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("getStateName")(
                                  _vm.dataInfo.gender,
                                  _vm.gander
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("el-descriptions-item", { attrs: { label: "年龄" } }, [
                    _vm._v(_vm._s(_vm.dataInfo.age || "-")),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "注册时间" } }, [
                    _vm._v(_vm._s(_vm.dataInfo.registerTime || "-")),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "学校" } }, [
                    _vm._v(_vm._s(_vm.dataInfo.schoolName || "-")),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "w-1 entry-order-activity-info-part-box fr-b-c m-t-30",
            },
            [
              _c(
                "div",
                { staticClass: "entry-order-activity-item-bg-box fl-s-s" },
                [
                  _c(
                    "div",
                    { staticClass: "entry-order-activity-title-box fr-s-c" },
                    [_vm._v("一年内入店")]
                  ),
                  _c("p", { staticClass: "entry-order-activity-number-box" }, [
                    _c("b", [
                      _vm._v(_vm._s(_vm.activeInfo.theLastYearEntryCount)),
                    ]),
                    _c("i", [_vm._v("次")]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "entry-order-activity-date-time-box fl-s-s",
                    },
                    [
                      _c("span", { staticClass: "date-title-text" }, [
                        _vm._v("上次入店"),
                      ]),
                      _c("span", { staticClass: "date-time-text" }, [
                        _vm._v(_vm._s(_vm.activeInfo.lastEntryTime || "-")),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "entry-order-activity-item-bg-box fl-s-s" },
                [
                  _c(
                    "div",
                    { staticClass: "entry-order-activity-title-box fr-s-c" },
                    [_vm._v("一年内订单")]
                  ),
                  _c("p", { staticClass: "entry-order-activity-number-box" }, [
                    _c("b", [
                      _vm._v(_vm._s(_vm.activeInfo.theLastYearOrderCount)),
                    ]),
                    _c("i", [_vm._v("次")]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "entry-order-activity-date-time-box fl-s-s",
                    },
                    [
                      _c("span", { staticClass: "date-title-text" }, [
                        _vm._v("上次下单"),
                      ]),
                      _c("span", { staticClass: "date-time-text" }, [
                        _vm._v(_vm._s(_vm.activeInfo.lastOrderTime || "-")),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "entry-order-activity-item-bg-box fl-s-s" },
                [
                  _c(
                    "div",
                    { staticClass: "entry-order-activity-title-box fr-s-c" },
                    [_vm._v("一年内活动")]
                  ),
                  _c("p", { staticClass: "entry-order-activity-number-box" }, [
                    _c("b", [
                      _vm._v(_vm._s(_vm.activeInfo.thisLastYearActivityCount)),
                    ]),
                    _c("i", [_vm._v("次")]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "entry-order-activity-date-time-box fl-s-s",
                    },
                    [
                      _c("span", { staticClass: "date-title-text" }, [
                        _vm._v("上次活动"),
                      ]),
                      _c("span", { staticClass: "date-time-text" }, [
                        _vm._v(_vm._s(_vm.activeInfo.lastActivityTime || "-")),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "w-1 id-info-part-wrap-box m-t-30" },
            [
              _c(
                "el-collapse",
                { staticClass: "id-info-collapse-part-box" },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "ID信息", name: "1" } },
                    [
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            title: "",
                            direction: "vertical",
                            column: 3,
                            border: "",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: "客户:",
                                "label-class-name": "my-label",
                                "content-class-name": "my-content",
                              },
                            },
                            [_vm._v(_vm._s(_vm.dataInfo.custId))]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "微信:" } },
                            [_vm._v(_vm._s(_vm.dataInfo.wxOfficialOpenId))]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "有赞:" } },
                            [_vm._v(_vm._s(_vm.dataInfo.yzOpenId))]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "小程序:" } },
                            [_vm._v(_vm._s(_vm.dataInfo.wxMiniOpenId))]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "会员:" } },
                            [_vm._v(_vm._s(_vm.dataInfo.memberId))]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "企微:" } },
                            [_vm._v(_vm._s(_vm.dataInfo.enWxExternalUserId))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "m-t-30" },
            [
              _vm.complaint.updateTime && _vm.complaint.demandContent
                ? _c(
                    "el-descriptions",
                    {
                      staticClass: "customer-complain-descriptions-box",
                      attrs: { title: "客户主诉", column: 1, border: "" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "extra" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btns",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.chiefGo },
                            },
                            [_vm._v("历史主诉列表")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "更新时间:" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.complaint ? _vm.complaint.updateTime : "-"
                            )
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "主诉内容:" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.complaint ? _vm.complaint.demandContent : "-"
                            )
                          ),
                        ]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _c("comprehensive"),
              _c("Interview"),
              _c("detection"),
              _c("SkinTesting"),
              _c("privateFile"),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.pageErrorMsg
      ? _c("div", { staticClass: "w-1 h-1 fl-c page-not-user-info-part-box" }, [
          _c("span", { staticClass: "color-90" }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }