<template>
  <div class="container">
    <div class="fr-b-c header">
      <span>客户私密档案（{{totalSize}}）</span>
      <el-button size="small" type="primary" class="btn" @click="clickAppendBtn">追加</el-button>
    </div>
    <div class="w-1 page-content-pagination-part-wrap-box">
      <div class="w-1 page-content-part-box">
        <el-card shadow="never" v-for="(item,index) in privateFileList" :key="index">
          <div class="w-1 fr-b-c color-90">
            <span class="m-r-30">{{item.createTime}}</span>
            <span class="flex-1 fr-e-c"><em class="flex-1 text-line-1 t-a-r">{{item.createUserName}}</em>录入</span>
          </div>
          <div class="w-1 fr-s-c m-t-30">
            <span>{{item.fileContent}}</span>
          </div>
        </el-card>
      </div>
      <el-pagination class="t-a-r" :pager-count="5" background layout="prev, pager, next" :page-size="query.pageSize" :total="totalSize" @current-change="changepage" v-if="totalSize&&privateFileList.length"></el-pagination>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import homeManager from "@/request/homeManager.js";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      query: {
        currentPage: 1, //当前分页
        pageSize: 10, //分页大小
      },
      privateFileList: [],  //私密档案列表
      totalSize: 0,

    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getPagePrivateFileData();  //获取页面数据
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {
    // 获取页面数据
    async getPagePrivateFileData () {
      try {
        let { code, data, message } = await homeManager.getPrivateFileList({ ...this.query, custId: this.$store.getters.custId })
        if (code == 200 && data) {
          this.privateFileList = data.dataList
          this.totalSize = data.totalSize
        }
      } catch (error) {
        console.log('获取页面数据', error)
      }
    },
    changepage (val) {
      this.query.currentPage = val
      this.getPagePrivateFileData();  //获取页面数据
    },


    // 点击追加按钮
    clickAppendBtn () {
      this.$router.push({
        path: '/privateFileAdd'
      })
    },

  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped lang="scss">
.container {
  margin-top: 30px;
  background: #ffffff;
}
// 头部
.header {
  padding: 20px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
  span {
    font-size: 28px;
    font-weight: 600;
  }
  .btn {
    font-size: 24px;
    padding: 15px 25px;
  }
}
// 内容
.page-content-pagination-part-wrap-box {
  padding-bottom: 20px;
  .page-content-part-box {
    padding: 20px 20px 0px;
    box-sizing: border-box;
    ::v-deep .el-card {
      height: 100%;
      font-size: 28px;
      margin-bottom: 20px;
      .el-card__body {
        padding: 30px 35px;
      }
    }
  }
}
// 分页
::v-deep .el-pagination{
  font-size: initial;
}
</style>
