var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "fr-b-c header" },
      [
        _c("span", [_vm._v("综合皮肤档案")]),
        _c(
          "el-button",
          {
            staticClass: "btn",
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.chiefGo },
          },
          [_vm._v("查看历史档案")]
        ),
      ],
      1
    ),
    _vm.tableData.length && _vm.activeNames
      ? _c(
          "div",
          { staticClass: "skin-archive-body-box" },
          [
            _c(
              "el-table",
              {
                staticClass: "tables",
                staticStyle: { width: "100%" },
                attrs: { size: "mini", data: _vm.tableInfo },
              },
              _vm._l(_vm.tableData, function (item, index) {
                return _c(
                  "el-table-column",
                  {
                    key: index,
                    attrs: {
                      prop: "date",
                      label: item.classifyName,
                      "min-width": "80",
                      align: "center",
                    },
                  },
                  [
                    item.skinFeatureFieldList.length > 0
                      ? _c(
                          "div",
                          _vm._l(
                            item.skinFeatureFieldList,
                            function (items, indexs) {
                              return _c("el-table-column", {
                                key: indexs,
                                attrs: {
                                  prop: "province",
                                  label: items.skinFeatureFieldNameDesc,
                                  "min-width": "80",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.$index == 0
                                            ? _c("div", [
                                                _c("span", [
                                                  _vm._v(_vm._s(items.value)),
                                                ]),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }
                          ),
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }