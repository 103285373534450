var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "fr-b-c header" },
      [
        _c("span", [_vm._v("客户私密档案（" + _vm._s(_vm.totalSize) + "）")]),
        _c(
          "el-button",
          {
            staticClass: "btn",
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.clickAppendBtn },
          },
          [_vm._v("追加")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "w-1 page-content-pagination-part-wrap-box" },
      [
        _c(
          "div",
          { staticClass: "w-1 page-content-part-box" },
          _vm._l(_vm.privateFileList, function (item, index) {
            return _c("el-card", { key: index, attrs: { shadow: "never" } }, [
              _c("div", { staticClass: "w-1 fr-b-c color-90" }, [
                _c("span", { staticClass: "m-r-30" }, [
                  _vm._v(_vm._s(item.createTime)),
                ]),
                _c("span", { staticClass: "flex-1 fr-e-c" }, [
                  _c("em", { staticClass: "flex-1 text-line-1 t-a-r" }, [
                    _vm._v(_vm._s(item.createUserName)),
                  ]),
                  _vm._v("录入"),
                ]),
              ]),
              _c("div", { staticClass: "w-1 fr-s-c m-t-30" }, [
                _c("span", [_vm._v(_vm._s(item.fileContent))]),
              ]),
            ])
          }),
          1
        ),
        _vm.totalSize && _vm.privateFileList.length
          ? _c("el-pagination", {
              staticClass: "t-a-r",
              attrs: {
                "pager-count": 5,
                background: "",
                layout: "prev, pager, next",
                "page-size": _vm.query.pageSize,
                total: _vm.totalSize,
              },
              on: { "current-change": _vm.changepage },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }