<template>
  <div class='container'>
    <div class="fr-b-c header">
      <span>综合皮肤档案</span>
      <el-button size="small" type="primary" class="btn" @click="chiefGo">查看历史档案</el-button>
    </div>
    <div class="skin-archive-body-box" v-if="tableData.length&&activeNames">
      <el-table class="tables" size="mini" :data="tableInfo" style="width: 100%;">
        <el-table-column prop="date" :label="item.classifyName" min-width="80" align="center" v-for="(item,index) in tableData" :key="index">
          <div v-if="item.skinFeatureFieldList.length>0">
            <el-table-column prop="province" :label="items.skinFeatureFieldNameDesc" min-width="80" v-for="(items,indexs) in item.skinFeatureFieldList" :key="indexs" align="center">
              <template slot-scope="scope">
                <div v-if="scope.$index==0">
                  <span>{{items.value}}</span>
                </div>
              </template>
            </el-table-column>
          </div>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import homeManager from "@/request/homeManager.js";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      requst: {
        module: '',
        url: '',
      },
      query: {},
      tableData: [],
      activeNames: '0',
      dataInfo: [],
      tableInfo: [{
        degree: '',
      }],
      skintest: {},
      degreeType: [],
      AreaType: [],
      colorType: [],
      skinType: [],
      enumType: [],
      sensitiveType: [],
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getLastRecord()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {
    async getLastRecord () {
      try {
        let { code, data } = await homeManager.skinSummary({ custId: this.$store.getters.custId })
        if (code == 200) {
          this.skintest = data
          this.tableData = data.classifyRootList
        }
      } catch (error) {
        //
      }
    },
    chiefGo () {
      this.$router.push({
        path: '/comprehensive'
      })
    }
  },
  filters: {
    getStateName (value, arr) {
      if (arr) {
        var array = arr.filter((obj) => {
          return value === obj.dictItemCode;
        });
        if (array.length !== 0) {
          return array[0].dictItemName;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  //  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped>
.container {
  margin-top: 30px;
  background: #ffffff;
}

.header {
  padding: 20px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
}

.header span {
  font-size: 28px;
  font-weight: 600;
}

.btn {
  font-size: 24px;
  padding: 15px 25px;
}

.tables {
  font-size: 20px;
}

.close {
  padding: 5px 0;
  border-radius: 5px;
}

.on {
  color: #fff;
}

.skin-archive-body-box {
  padding: 20px 20px;
  box-sizing: border-box;
}

::v-deep .el-table .el-table__cell {
  padding: 5px 0;
}

::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 10px;
}
</style>