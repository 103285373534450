<template>
  <div class='container'>
    <div class="fr-b-c header" v-if="dataInfo">
      <span>检测档案（{{dataInfo.recordTime}}）</span>
      <el-button size="small" type="primary" class="btn" @click="chiefGo">查看历史档案</el-button>
    </div>
    <div class="detection-body-box">
      <el-descriptions title="" :column="2" border v-if="dataInfo">
        <el-descriptions-item label="肤色:">{{dataInfo.skinColor[0].value|getStateName(colorType)}}</el-descriptions-item>
        <el-descriptions-item label="肤龄:">{{Number(dataInfo.skinAge[0].value)}}</el-descriptions-item>
      </el-descriptions>
      <el-table size="mini" class="tables" :data="tableInfo" style="width: 100%;">
        <el-table-column prop="degree" label="" width="50" align="center" fixed>
        </el-table-column>
        <el-table-column prop="date" :label="item.classifyName" min-width="70" align="center" v-for="(item,index) in tableData" :key="index">
          <el-table-column prop="province" :label="items.classifyName" min-width="70" v-for="(items,indexs) in item.leafClassifyList" :key="indexs" align="center">
            <template slot-scope="scope">
              <div v-if="scope.$index==0">
                <div :class="['close',item2.color?'on':'']" :style="{background: item2.color?item2.color:'#f7f7f7'}" v-for="(item2,index2) in items.level" :key="index2">
                  {{item2.direction=='right'?'右':item2.direction=='left'?'左':'正'}}:{{item2.value}}</div>
              </div>
              <div v-if="scope.$index==1">
                <div :class="['close',item2.color?'on':'']" :style="{background: item2.color?item2.color:'#f7f7f7'}" v-for="(item2,index2) in items.score" :key="index2">
                  {{item2.direction=='right'?'右':item2.direction=='left'?'左':'正'}}:{{$util.fmtToFix(item2.value,1)}}</div>
              </div>
              <div v-if="scope.$index==2">
                <div :class="['close',item2.color?'on':'']" :style="{background: item2.color?item2.color:'#f7f7f7'}" v-for="(item2,index2) in items.ratio" :key="index2">
                  {{item2.direction=='right'?'右':item2.direction=='left'?'左':'正'}}:{{$util.fmtToFix(item2.value,1)}}</div>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="数据解读" name="1">
          <el-table size="mini" class="tables" border :data="explainList" style="width: 100%;">
            <el-table-column label="指标名" prop="fileNameDesc" min-width="85" fixed>
            </el-table-column>
            <el-table-column label="脸向" prop="direction" min-width="80">
            </el-table-column>
            <el-table-column label="值/范围" prop="configValue" min-width="80">
              <template slot-scope="scope">{{scope.row.configValue=='大于：0,小于：1000'?'0-100':scope.row.configValue}}</template>
            </el-table-column>
            <el-table-column label="现值" prop="matchValue" min-width="80">
              <template slot-scope="scope">{{$util.fmtToFix(scope.row.matchValue,1)}}</template>
            </el-table-column>
            <el-table-column label="异常" prop="color" min-width="80">
              <template>是</template>
            </el-table-column>
            <el-table-column label="解读内容" prop="explainMsg" min-width="80">
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </div>

  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import homeManager from "@/request/homeManager.js";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      requst: {
        module: '',
        url: '',
      },
      query: {},
      tableData: [],
      activeNames: '0',
      dataInfo: null,
      explainList: [],
      colorType: [],
      tableInfo: [],
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.terminal()
    this.getBySourceType()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {
    async terminal () {
      try {
        let { code, data } = await homeManager.terminal({ custId: this.$store.getters.custId })
        if (code == 200) {
          this.dataInfo = data
          this.explainList = data.explainList
          this.tableData = data.classifyRootList
          this.tableData.forEach(item => {
            item.leafClassifyList.forEach(items => {
              this.sortFun(items.level)
              this.sortFun(items.score)
              this.sortFun(items.ratio)
            })
          });
          this.tableInfo = [{
            degree: '程度',
            list: [],
            list_TWO: []
          }, {
            degree: '得分',
            list: [],
            list_TWO: []
          }, {
            degree: '面积占比',
            list: [],
            list_TWO: []
          }]
        }
      } catch (error) {
        //
      }
    },
    sortFun (arr) {
      // items.level.map(ele => ele.direction)
      arr.sort(function (a, b) {
        // order是规则  objs是需要排序的数组
        let order = ['left', 'middle', 'right'];
        return order.indexOf(a.direction) - order.indexOf(b.direction);
      });
      // 根据规则排序后新的数组
      let result = arr.map(function (a) {
        return a['direction'];
      });
    },
    //肤色
    async getBySourceType () {
      try {
        let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerAiDetectSkinColor' })
        if (code == 200) {
          this.colorType = data
        }
      } catch (error) {
        //
      }
    },
    chiefGo () {
      this.$router.push({
        path: '/detection'
      })
    }
  },
  filters: {
    getStateName (value, arr) {
      if (arr) {
        var array = arr.filter((obj) => {
          return value === obj.dictItemCode;
        });
        if (array.length !== 0) {
          return array[0].dictItemName;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  //  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped lang="scss">
.container {
  margin-top: 30px;
  background: #ffffff;
}
.header {
  padding: 20px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
}

.header span {
  font-size: 28px;
  font-weight: 600;
}

.btn {
  font-size: 24px;
  padding: 15px 25px;
}

.tables {
  margin-top: 20px;
  font-size: 20px;
}

.el-descriptions {
  font-size: 22px;
}

.close {
  padding: 10px 0;
  margin-bottom: 5px;
  border-radius: 5px;
}

.on {
  color: #fff;
}

::v-deep .el-table .el-table__cell {
  padding: 5px 0;
}

::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 10px;
}

.detection-body-box {
  padding: 20px 20px;
  box-sizing: border-box;
  ::v-deep .el-descriptions{
    .el-descriptions-item__label {
      width: 100px;
    }
  }
}
</style>