<template>
  <div class='container'>
    <div class="fr-b-c header">
      <span>面诊档案 {{skintest?`(${skintest.interviewTime})`:''}}</span>
      <el-button size="small" type="primary" class="btn" @click="chiefGo">查看历史档案</el-button>
    </div>
    <div class="interview-file-body-box" v-if="tableData.length&&activeNames">
      <el-table class="tables" size="mini" :data="tableInfo" style="width: 100%;">
        <el-table-column prop="degree" label="" width="50" align="center" fixed>
        </el-table-column>
        <el-table-column prop="date" :label="item.classifyName" min-width="80" align="center" v-for="(item,index) in tableData" :key="index">
          <div v-if="item.leafClassifyList.length>0">
            <el-table-column prop="province" :label="items.classifyName" min-width="80" v-for="(items,indexs) in item.leafClassifyList" :key="indexs" align="center">
              <template slot-scope="scope">
                <div v-if="scope.$index==0">
                  <div v-if="items.level" :class="['close',items.level.color?'on':'']" :style="{background: items.level.color?items.level.color:'#f7f7f7'}">
                    <span v-if="items.classifyCode=='skinColor'">{{items.level.value|getStateName(colorType)}}</span>
                    <span v-else>{{items.level.value|getStateName(degreeType)}}</span>
                  </div>
                </div>
                <div v-if="scope.$index==1">
                  <div v-if="items.area" :class="['close',items.area.color?'on':'']" :style="{background: items.area.color?items.area.color:'#f7f7f7'}">
                    {{items.area.value|getStateName(AreaType)}}
                  </div>
                </div>
              </template>
            </el-table-column>
          </div>
          <el-table-column v-else prop="date" label="" min-width="80" align="center" :key="index">
            <template slot-scope="scope">
              <div v-if="scope.$index==0">
                <div v-if="item.level" :class="['close',item.level.color?'on':'']" :style="{background: item.level.color?item.level.color:'#f7f7f7'}">
                  <span v-if="item.classifyCode=='skinColor'">{{item.level.value|getStateName(colorType)}}</span>
                  <span v-else-if="item.classifyCode=='skinType'">{{item.level.value|getStateName(skinType)}}</span>
                  <span v-else-if="item.classifyCode=='roughSkin'||item.classifyCode=='roughPore'">{{item.level.value|getStateName(enumType)}}</span>
                  <span v-else-if="item.classifyCode=='sensitiveType'">{{item.level.value|getStateName(sensitiveType)}}</span>
                  <span v-else>{{item.level.value|getStateName(degreeType)}}</span>
                </div>
              </div>
              <div v-if="scope.$index==1">
                <div v-if="item.area" :class="['close',item.area.color?'on':'']" :style="{background: item.area.color?item.area.color:'#f7f7f7'}">
                  {{item.area.value|getStateName(AreaType)}}
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="数据解读" name="1">
          <el-table size="mini" class="tables" border :data="skintest.explainList" style="width: 100%;">
            <el-table-column label="指标名" prop="fileNameDesc" min-width="60" fixed align="center">
            </el-table-column>
            <el-table-column label="值/范围" prop="configValue" min-width="80" align="center">
              <template slot-scope="scope">{{scope.row.configValue=='大于：0, 小于：1000'?'0-100':scope.row.configValue}}</template>
            </el-table-column>
            <el-table-column label="现值" prop="matchValue" min-width="80" align="center">
            </el-table-column>
            <el-table-column label="异常" prop="color" min-width="80" align="center">
              <template>是</template>
            </el-table-column>
            <el-table-column label="解读内容" prop="explainMsg" min-width="80" align="center">
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </div>

  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import homeManager from "@/request/homeManager.js";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      requst: {
        module: '',
        url: '',
      },
      query: {},
      tableData: [],
      activeNames: '0',
      dataInfo: [],
      tableInfo: [{
        degree: '程度',
      }, {
        degree: '面积',
      }],
      skintest: {},
      degreeType: [],
      AreaType: [],
      colorType: [],
      skinType: [],
      enumType: [],
      sensitiveType: [],
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getBySensitiveType()
    this.getByYesNoEnum()
    this.getByColorType()
    this.getBySourceType()
    this.getAreaInfo()
    this.getByskinType()
    this.getLastRecord()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {
    async getLastRecord () {
      try {
        let { code, data } = await homeManager.getLastRecord({ custId: this.$store.getters.custId })
        if (code == 200) {
          this.skintest = data
          this.tableData = data.classifyRootList
        }
      } catch (error) {
        //
      }
    },
    //程度
    async getBySourceType () {
      try {
        let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerInterviewLevel' })
        if (code == 200) {
          this.degreeType = data
        }
      } catch (error) {
        //
      }
    },
    //面积
    async getAreaInfo () {
      try {
        let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerInterviewArea' })
        if (code == 200) {
          this.AreaType = data
        }
      } catch (error) {
        //
      }
    },
    //肤色
    async getByColorType () {
      try {
        let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerAiDetectSkinColor' })
        if (code == 200) {
          this.colorType = data
        }
      } catch (error) {
        //
      }
    },
    //肤质
    async getByskinType () {
      try {
        let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerInterviewSkinType' })
        if (code == 200) {
          this.skinType = data
        }
      } catch (error) {
        //
      }
    },
    //有无
    async getByYesNoEnum () {
      try {
        let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerInterviewYesNoEnum' })
        if (code == 200) {
          this.enumType = data
        }
      } catch (error) {
        //
      }
    },//敏感性
    async getBySensitiveType () {
      try {
        let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerInterviewSensitiveType' })
        if (code == 200) {
          this.sensitiveType = data
        }
      } catch (error) {
        //
      }
    },
    chiefGo () {
      this.$router.push({
        path: '/Interview'
      })
    }
  },
  filters: {
    getStateName (value, arr) {
      if (arr) {
        var array = arr.filter((obj) => {
          return value === obj.dictItemCode;
        });
        if (array.length !== 0) {
          return array[0].dictItemName;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  //  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped>
.container {
  margin-top: 30px;
  background: #ffffff;
}
.header {
  padding: 20px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
}

.header span {
  font-size: 28px;
  font-weight: 600;
}

.btn {
  font-size: 24px;
  padding: 15px 25px;
}

.tables {
  font-size: 20px;
}

.close {
  padding: 5px 0;
  border-radius: 5px;
}

.on {
  color: #fff;
}
::v-deep .el-table .el-table__cell {
  padding: 5px 0;
}
::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 10px;
}

.interview-file-body-box {
  padding: 20px 20px;
  box-sizing: border-box;
}
</style>