<template>
  <div class='page-home-box'>
    <!-- 页面有数据内容部分 -->
    <div class="w-1" v-if="dataInfo">
      <div class="header">
        <div class="h-1">
          <img class="headerImg" :src="dataInfo.avatarUrl" alt="">
        </div>
        <el-descriptions size="mini" style="flex: 1;" direction="vertical" title="" :column="3" border>
          <el-descriptions-item label="昵称" label-class-name="my-label" content-class-name="my-content">{{dataInfo.custName}}</el-descriptions-item>
          <el-descriptions-item label="用户类型">{{dataInfo.vipLevel}}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{dataInfo.custPhone}}</el-descriptions-item>
          <el-descriptions-item label="性别">
            <el-tag v-if="dataInfo.gender" size="small">{{dataInfo.gender | getStateName(gander)}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="年龄">{{dataInfo.age||'-'}}</el-descriptions-item>
          <el-descriptions-item label="注册时间">{{dataInfo.registerTime||'-'}}</el-descriptions-item>
          <el-descriptions-item label="学校">{{dataInfo.schoolName||'-'}}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="w-1 entry-order-activity-info-part-box fr-b-c m-t-30">
        <div class="entry-order-activity-item-bg-box fl-s-s">
          <div class="entry-order-activity-title-box fr-s-c">一年内入店</div>
          <p class="entry-order-activity-number-box"><b>{{activeInfo.theLastYearEntryCount}}</b><i>次</i></p>
          <div class="entry-order-activity-date-time-box fl-s-s">
            <span class="date-title-text">上次入店</span>
            <span class="date-time-text">{{activeInfo.lastEntryTime||'-'}}</span>
          </div>
        </div>
        <div class="entry-order-activity-item-bg-box fl-s-s">
          <div class="entry-order-activity-title-box fr-s-c">一年内订单</div>
          <p class="entry-order-activity-number-box"><b>{{activeInfo.theLastYearOrderCount}}</b><i>次</i></p>
          <div class="entry-order-activity-date-time-box fl-s-s">
            <span class="date-title-text">上次下单</span>
            <span class="date-time-text">{{activeInfo.lastOrderTime||'-'}}</span>
          </div>
        </div>
        <div class="entry-order-activity-item-bg-box fl-s-s">
          <div class="entry-order-activity-title-box fr-s-c">一年内活动</div>
          <p class="entry-order-activity-number-box"><b>{{activeInfo.thisLastYearActivityCount}}</b><i>次</i></p>
          <div class="entry-order-activity-date-time-box fl-s-s">
            <span class="date-title-text">上次活动</span>
            <span class="date-time-text">{{activeInfo.lastActivityTime||'-'}}</span>
          </div>
        </div>
      </div>
      <div class="w-1 id-info-part-wrap-box m-t-30">
        <el-collapse class="id-info-collapse-part-box">
          <el-collapse-item title="ID信息" name="1">
            <el-descriptions title="" direction="vertical" :column="3" border>
              <el-descriptions-item label="客户:" label-class-name="my-label" content-class-name="my-content">{{dataInfo.custId}}</el-descriptions-item>
              <el-descriptions-item label="微信:">{{dataInfo.wxOfficialOpenId}}</el-descriptions-item>
              <el-descriptions-item label="有赞:">{{dataInfo.yzOpenId}}</el-descriptions-item>
              <el-descriptions-item label="小程序:">{{dataInfo.wxMiniOpenId}}</el-descriptions-item>
              <el-descriptions-item label="会员:">{{dataInfo.memberId}}</el-descriptions-item>
              <el-descriptions-item label="企微:">{{dataInfo.enWxExternalUserId}}</el-descriptions-item>
            </el-descriptions>
          </el-collapse-item>

        </el-collapse>
      </div>
      <div class="m-t-30">
        <el-descriptions class="customer-complain-descriptions-box" title="客户主诉" :column="1" border v-if="complaint.updateTime&&complaint.demandContent">
          <template slot="extra">
            <el-button class="btns" type="primary" size="small" @click="chiefGo">历史主诉列表</el-button>
          </template>
          <el-descriptions-item label="更新时间:">{{complaint?complaint.updateTime:'-'}}</el-descriptions-item>
          <el-descriptions-item label="主诉内容:">{{complaint?complaint.demandContent:'-'}}</el-descriptions-item>
        </el-descriptions>
        <!-- 综合皮肤档案 -->
        <comprehensive></comprehensive>
        <!-- 面诊档案 -->
        <Interview></Interview>
        <!-- 检测档案 -->
        <detection></detection>
        <!-- 小程序测肤 -->
        <SkinTesting></SkinTesting>
        <!-- 客户私密档案 -->
        <privateFile></privateFile>
      </div>
    </div>

    <!-- 获取外部联系人不成功 -->
    <div class="w-1 h-1 fl-c page-not-user-info-part-box" v-if="pageErrorMsg">
      <span class="color-90"></span>
    </div>

  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import Interview from './components/Interview.vue'
import detection from './components/detection.vue'
import SkinTesting from './components/SkinTesting.vue'
import comprehensive from './components/comprehensive'
import privateFile from './components/privateFile.vue'
import homeManager from "@/request/homeManager.js";
import { set_config } from '@/tools/sdk-set-config.js'
export default {
  mixins: [set_config],
  // import引入的组件需要注入到对象中才能使用
  components: {
    Interview,
    detection,
    SkinTesting,
    comprehensive,
    privateFile
  },
  data () {
    // 这里存放数据
    return {
      dataInfo: null,//用户信息
      activeInfo: {},//活跃信息
      complaint: {},//客户诉求
      gander: [],
      isPageStoreUserData: true,  //页面是否在data存放数据
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  async created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {
    async getInitRefreshPageInfo () {
      await this.getDictByCode()
      await this.getCustomerActiveInfo()
      await this.getLastDemand()
    },
    //获取性别字典信息
    async getDictByCode () {
      try {
        let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerGender' })
        if (code == 200) {
          this.gander = data
        }
      } catch (error) {
        //
      }
    },
    //获取用户活跃度信息
    async getCustomerActiveInfo () {
      try {
        let { code, data } = await homeManager.getCustomerActiveInfo({ custId: this.$store.getters.custId })
        if (code == 200) {
          this.activeInfo = data
        }
      } catch (error) {
        //
      }
    },
    //获取最近主诉
    async getLastDemand () {
      try {
        let { code, data } = await homeManager.getLastDemand({ custId: this.$store.getters.custId })
        if (code == 200) {
          this.complaint = data
        }
      } catch (error) {
        //
      }
    },
    //历史主诉页面
    chiefGo () {
      this.$router.push({
        path: '/chiefComplaint'
      })
    },
    goInfo () {
      this.$router.push({
        path: '/introduction'
      })
    },
    goPage (val) {
      switch (val) {
        case 1:
          this.$router.push({
            path: '/Interview'
          })
          break;
        case 2:
          this.$router.push({
            path: '/detection'
          })
          break;
        case 3:
          this.$router.push({
            path: '/SkinTesting'
          })
          break;
        case 4:
          this.$router.push({
            path: '/orderInfo'
          })
          break;
        case 5:
          this.$router.push({
            path: '/storeRecord'
          })
          break;
        case 6:
          this.$router.push({
            path: '/activity'
          })
          break;
        default:
          break;
      }

    }
  },
  filters: {
    getStateName (value, arr) {
      if (arr) {
        var array = arr.filter((obj) => {
          return value === obj.dictItemCode;
        });
        if (array.length !== 0) {
          return array[0].dictItemName;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  //  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped lang="scss">
.page-home-box {
  min-height: 100vh;
  background: linear-gradient(102.84deg, #f3fbf9 0%, #f1f3f9 89.8%);
  padding: 20px 0px;
  box-sizing: border-box;
}

.header {
  display: flex;
  background: #ffffff;
  padding: 20px 20px;
  box-sizing: border-box;
}

.headerImg {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 10px;
  margin-right: 10px;
}

.table {
  font-size: 26px;
}

td {
  padding: 10px;
}

.title {
  font-size: 28px;
  font-weight: 600;
}

.listInfo {
  font-size: 34px;
  color: #1989fa;
}

.times {
  font-size: 24px;
  color: #333;
  margin: 20px 0;
  font-weight: 600;
}

.coment {
  font-size: 24px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
}

.el-descriptions {
  font-size: 22px;
}

::v-deep .el-descriptions__title {
  font-size: 28px;
}

.btns {
  font-size: 24px;
  padding: 15px 25px;
}

/* 获取外部联系人不成功 */
.page-not-user-info-part-box {
  font-size: 30px;
}

::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 10px;
}

/* id信息 */
.id-info-collapse-part-box {
  border: none;
  ::v-deep .el-collapse-item {
    .el-collapse-item__header {
      padding: 0px 20px;
      border-bottom-color: transparent;
      font-weight: bold;
      &.is-active {
        border-bottom-color: #ebeef5;
      }
    }
    .el-collapse-item__content {
      padding: 20px 20px 20px;
    }
  }
}

/* 客户主诉 */
.customer-complain-descriptions-box {
  background: #ffffff;
  ::v-deep .el-descriptions__header {
    margin-bottom: 0px;
    padding: 20px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #e5e5e5;
  }
  ::v-deep .el-descriptions__body {
    padding: 30px 20px;
    box-sizing: border-box;
    .el-descriptions-item__label {
      width: 140px;
    }
  }
}

/* 入店、订单、活动 */
.entry-order-activity-info-part-box {
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
  .entry-order-activity-item-bg-box {
    width: 32%;
    // height: 226px;
    background: #ffffff;
    border-radius: 6px;
    padding: 25px 20px;
    box-sizing: border-box;
    .entry-order-activity-title-box {
      font-size: 22px;
      color: #909090;
      padding: 0px 22px;
      position: relative;
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        background: #1989fa;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
      }
    }
    .entry-order-activity-number-box {
      font-size: 38px;
      color: #333333;
      margin-top: 25px;
      b {
        font-size: 38px;
        font-weight: bold;
      }
      i {
        font-size: 22px;
        color: #909090;
        margin-left: 8px;
      }
    }
    .entry-order-activity-date-time-box {
      font-size: 20px;
      color: #333333;
      margin-top: 35px;
      
      .date-title-text {
        color: #909090;
        margin-bottom: 4px;
      }
    }
  }
}
</style>