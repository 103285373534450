import BaseModule from './utils/BaseModule'
class BaseManager extends BaseModule {
  constructor() {
    super()
  }

  // 对url签名
  getAgentConfigSignInfo (param) {
    return this.postJson('/enwx/sign', param)
  }


}

export default new BaseManager()

