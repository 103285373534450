let wx = window.wx
import sdkConfigSign from '@/request/sdkConfigSign.js'
import homeManager from "@/request/homeManager.js";
import { Toast } from 'vant';
export const set_config = {
  data () {
    return {
      pageErrorMsg: '',  //页面错误提示展示
      enWxExternalUserId: 'wmkRS_ZQAAWwWu4DkkSxLgAJChnBpRFA',  //企业微信外部联系人ID
      isPageStoreUserData: false,  //页面是否在data存放数据
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    if (process.env.NODE_ENV !== 'development') this.getSetAgentConfigData();  //设置config
    if (process.env.NODE_ENV === 'development') this.getPageExternalUserBasicMultiId();  //获取用户基本信息--企微外部联系人
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {
    // 设置config
    async getSetAgentConfigData () {
      let url_href = location.href.split('#')[0];
      console.log('url_href', url_href, location.href)
      try {
        let { code, data, message } = await sdkConfigSign.getAgentConfigSignInfo({ url: url_href })
        console.log('getAgentConfigSignInfo', code, data, message)
        if (code == 200 && data) {
          console.log('getAgentConfigSignInfo-data', data)
          let sign_obj = {
            corpid: data.corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
            agentid: data.agentId, // 必填，企业微信的应用id （e.g. 1000247）
            timestamp: Number(data.timestamp), // 必填，生成签名的时间戳
            nonceStr: data.noncestr, // 必填，生成签名的随机串
            signature: data.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
            jsApiList: ['getContext', 'getCurExternalContact'], //必填，传入需要使用的接口名称
          }
          console.log('agentConfig应用身份-sign_obj', sign_obj);
          wx.agentConfig({
            ...sign_obj,
            success: (agentRes) => {
              console.log('agentConfig应用身份', agentRes)
              this.getEnterPageContextData();  //获取进入H5页面的入口环境
            },
            fail: (agentErr) => {
              console.log('agentConfig应用身份-agentErr', agentErr)
              if (agentErr.errMsg.indexOf('function not exist') > -1) {
                this.pageErrorMsg = '版本过低请升级'  //页面错误提示展示
              } else {
                this.pageErrorMsg = '注入应用的权限失败'  //页面错误提示展示
              }
            }
          });
        }
      } catch (error) {
        console.error('config签名', error)
        this.pageErrorMsg = '获取config签名错误，不可查看信息'  //页面错误提示展示
      }
    },
    // 获取进入H5页面的入口环境
    getEnterPageContextData () {
      wx.invoke('getContext', {}, (contextRes) => {
        console.log('获取进入H5页面的入口环境-getContext', contextRes)
        if (contextRes.err_msg == "getContext:ok") {
          // contact_profile:从联系人详情进入、single_chat_tools:从单聊会话的工具栏进入
          if (contextRes.entry === 'contact_profile' || contextRes.entry === 'single_chat_tools') {
            this.getCurExternalContactInfoData(); //获取当前外部联系人userid
          } else {
            Toast.fail('当前进入页面方式不对，不可查看信息');
            this.pageErrorMsg = '当前进入页面方式不对，不可查看信息'  //页面错误提示展示
          }
        } else {
          Toast.fail('当前进入页面错误，不可查看信息');
          this.pageErrorMsg = '当前进入页面错误，不可查看信息'  //页面错误提示展示
        }
      })
    },
    // 获取当前外部联系人userid
    getCurExternalContactInfoData () {
      wx.invoke('getCurExternalContact', {}, (contactRes) => {
        console.log('获取当前外部联系人userid-getCurExternalContact', contactRes)
        if (contactRes.err_msg == "getCurExternalContact:ok") {
          this.enWxExternalUserId = contactRes.userId  //企业微信外部联系人ID
          this.getPageExternalUserBasicMultiId();  //获取用户基本信息--企微外部联系人
        } else {
          Toast.fail('获取当前外部联系人错误，不可查看信息');
          this.pageErrorMsg = '获取当前外部联系人错误，不可查看信息'  //页面错误提示展示
        }
      })
    },


    // 获取用户基本信息--企微外部联系人
    async getPageExternalUserBasicMultiId () {
      try {
        let { code, data } = await homeManager.getByMultiId({ enWxExternalUserId: this.enWxExternalUserId })
        if (code == 200 && data) {
          if (this.isPageStoreUserData) this.dataInfo = data;  //页面是否在data存放数据
          this.$store.dispatch('admin/setCustId', data.custId);
          this.getInitRefreshPageInfo();   //啥刷新首页数据
        } else {
          this.pageErrorMsg = '获取外部联系人信息失败'
        }
      } catch (error) {
        this.pageErrorMsg = `获取外部联系人信息失败:${error.message}`
      }
    }



  },
}